// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-collections-letters-js": () => import("./../../../src/pages/collections/letters.js" /* webpackChunkName: "component---src-pages-collections-letters-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shopping-bag-js": () => import("./../../../src/pages/shopping-bag.js" /* webpackChunkName: "component---src-pages-shopping-bag-js" */)
}

